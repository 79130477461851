import React from 'react';
import { useState, useEffect } from 'react';
import fetchJsonp from 'fetch-jsonp';

// Context
import { useFiltersContext } from '../context/FiltersContext';

const SmartFeedFilters = ({ siteConfig, apiKey }) => {
  const {
    filtersState: { options, keywords, autocomplete, filters },
    loading,
    setLoading,
    fetchOptions,
    onKeywordsChange,
    onSelectionChange,
    onLocationSelected,
    onLocationChange,
    resetLocation,
    resetFilters,
  } = useFiltersContext();
  const [autoCompleteFilter, setAutoCompleteFilter] = useState([]);
  const [autocompleteListHide, setAutocompleteListHide] = useState(true);

  useEffect(() => {
    var optionNames = [];
    optionNames = [
      { func: 'GetRegions', name: 'Regions', key: 'regions' },
      { func: 'GetCategories', name: 'Categories', key: 'categories' },
      { func: 'GetJobTypes', name: 'Job Types', key: 'jobTypes' },
      {
        func: 'GetPostingTypes',
        name: 'Posting Types',
        key: 'postingTypes',
      },
      { func: 'GetIndustries', name: 'Industries', key: 'industries' },
      { func: 'GetLocations', name: 'Locations', key: 'locations' },
    ];

    fetchOptions(siteConfig.sfAPIEndpoint, apiKey, optionNames);
  }, []);

  const autocompleteSearchChange = (e) => {
    setAutocompleteListHide(false);

    let val = e.target.value;

    onLocationChange(val);

    if (val.length > 2) {
      // Do api call to get key
      fetchJsonp(
        siteConfig.sfAPIEndpoint + '/CareerPage/GetLocationAPIKey?id=' + apiKey,
        {
          timeout: 15000,
        }
      )
        .then(function(response) {
          return response.json();
        })
        .then(function(geoKey) {
          // Do api call to get locs
          fetch(
            'https://geocoding.vacancy-filler.co.uk' +
              '/api/V2/exact/?apikey=' +
              geoKey.key +
              '&search=' +
              encodeURIComponent(val)
          )
            .then((res) => res.json())

            .then((result) => {
              setAutoCompleteFilter(result.locationResults);
            });
        });
    } else {
      setAutocompleteListHide(true);
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      var optionsPosition = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      function errorPosition(err) {
        console.error(`We couldn't retrieve your location`, err);
      }

      function showPosition(position) {
        setAutocompleteListHide(true);

        onLocationSelected({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          distance: 15,
          displayName: 'Current Location',
        });
      }

      navigator.geolocation.getCurrentPosition(
        showPosition,
        errorPosition,
        optionsPosition
      );
    } else {
      console.warn('Geolocation is not supported by your browser');
    }
  };

  const renderFilter = (label, key, option) => {
    if (label === 'Keyword Search') {
      return (
        <div className="vf-filter">
          <label htmlFor="keywords">{label}: </label>
          <div className="vf-filter-wrap">
            <input
              value={keywords}
              onChange={onKeywordsChange}
              className="VF-form-control onchange-search form-control"
              id="keywords"
              name={key}
              placeholder="Enter keywords"
              type="text"
            />

            <div className="filter-icon">
              <span
                onClick={() => onKeywordsChange({ target: { value: '' } })}
                className={`fas fa-times right-icon ${!keywords &&
                  'hidden'} clear-icon`}
                title="Clear"
              ></span>
            </div>
          </div>
        </div>
      );
    } else if (label === 'Location Search') {
      return (
        <div className="vf-filter">
          <label htmlFor={'select-' + label}>{label}: </label>
          <div className="vf-filter-wrap">
            <input
              value={autocomplete}
              onChange={autocompleteSearchChange}
              className="VF-form-control onchange-search form-control"
              id={'select-' + label}
              name={label}
              placeholder="Enter postcode, town or city"
              type="text"
            />
            {!autocompleteListHide &&
              autoCompleteFilter &&
              Array.isArray(autoCompleteFilter) &&
              autoCompleteFilter.length > 0 && (
                <div className="vf-filter-loc-complete-list">
                  {autoCompleteFilter.map((loc, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        setAutocompleteListHide(true);
                        onLocationSelected({ ...loc, distance: 10 });
                      }}
                    >
                      {loc.displayName}
                    </div>
                  ))}
                </div>
              )}
            <div className="filter-icon">
              <span
                aria-hidden="true"
                onClick={() => getCurrentLocation()}
                className={`far fa-dharmachakra right-icon ${autocomplete &&
                  'hidden'} autocomplete-get-location`}
                title="Get your location"
              ></span>
              <span
                aria-hidden="true"
                onClick={() => {
                  setAutocompleteListHide(true);
                  resetLocation();
                }}
                className={`fas fa-undo right-icon ${!autocomplete &&
                  'hidden'} autocomplete-get-location`}
                title="Reset Location"
              ></span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="vf-filter">
          <label htmlFor={'select-' + key}>{label}: </label>
          <select
            className="form-control"
            value={filters[key] || options[option][0]}
            onChange={onSelectionChange}
            name={key}
            id={'select-' + key}
          >
            {options[option].map((item, i) => (
              <option key={i} value={i === 0 ? 'All' : item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      );
    }
  };

  return (
    <div className="filters">
      <div className="page-search-input-group w-100">
        <div className="col-12">
          {renderFilter('Keyword Search', 'keywords', 'keywords')}
        </div>
      </div>
      <div className="page-search-input-group page-search-input-group-advanced w-100">
        <div className="col-12 col-lg-6">
          {renderFilter('Filter by Category', 'category', 'categories')}
        </div>
        <div className="col-12 col-lg-6">
          {renderFilter('Filter by Region', 'region', 'regions')}
        </div>
        <div className="col-12 mt-3">
          <button
            className="btn btn-outline-danger float-right"
            onClick={() => resetFilters()}
          >
            Reset Filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default SmartFeedFilters;
